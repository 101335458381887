import Dropdown from '@/components/Dropdown.vue';
import InputField from '@/components/InputField.vue';
import { defineComponent, computed, ref, } from 'vue';
import OptionsView from './OptionsView.vue';
export default defineComponent({
    name: 'BaseSelect',
    components: {
        InputField,
        Dropdown,
        OptionsView,
    },
    props: {
        modelValue: {
            type: [String, Number],
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        placeholder: {
            type: String,
            required: false,
            default: '',
        },
    },
    emits: ['update:modelValue', 'change'],
    setup(props, { emit }) {
        const dropdown = ref(null);
        const selectedLabel = computed(() => {
            const target = props.options.find((item) => item.value === props.modelValue);
            return target?.label || '';
        });
        const selectItem = (item) => {
            emit('update:modelValue', item.value);
            emit('change', item);
            if (dropdown.value) {
                dropdown.value.hide();
            }
        };
        return {
            dropdown,
            selectedLabel,
            selectItem,
        };
    },
});
