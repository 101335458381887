import { reactive } from 'vue';
const useHistory = (key) => {
    const state = reactive(JSON.parse(localStorage.getItem(key) || '[]'));
    const add = (chainType, token) => {
        const currentIndex = state.findIndex((item) => item.chainType === chainType);
        if (currentIndex === -1) {
            const currentState = {
                chainType,
                record: [token],
            };
            state.push(currentState);
        }
        else {
            // eslint-disable-next-line no-lonely-if
            if (state[currentIndex].record.indexOf(token) === -1) {
                state[currentIndex].record.unshift(token);
                state[currentIndex].record = state[currentIndex].record.slice(0, 5);
            }
        }
        localStorage.setItem(key, JSON.stringify(state));
    };
    const get = (chainType) => {
        const currentIndex = state.findIndex((item) => item.chainType === chainType);
        if (currentIndex === -1) {
            return [];
        }
        return state[currentIndex].record;
    };
    return {
        add,
        get,
    };
};
export default useHistory;
