import Backend from '@/core/Services/Backend';
// import { AddressItem } from '@/core/types/AddressItem';
import useRequest from '@/utils/useRequest';
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import BaseUploader from './BaseUploader.vue';
/**
 * 上传批量空投文件
 * @desc 支持文件类型：Excel/CSV/Txt
 */
export default defineComponent({
    name: 'UploadFile',
    components: {
        BaseUploader,
    },
    props: {
        standard: {
            required: false,
            type: String,
        },
    },
    emits: {
        // imported: (val: AddressItem[]) => val,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        imported: (val) => val,
    },
    setup(props, { emit }) {
        const { t } = useI18n();
        const { pending, submit } = useRequest();
        const addHandler = (file) => {
            submit(async (cancel) => {
                const result = await Backend.importAirdrop(file, cancel, props.standard);
                emit('imported', result);
            });
        };
        return {
            t,
            addHandler,
            pending,
        };
    },
});
