/*
 * @Author: shadow
 * @Date: 2022-01-05
 * @Description: 对象数组的一些操作，对象应为同一类型
 * @FilePath: \onto-anydrop\src\utils\arrayOfObjects.ts
 */
import { cloneDeep } from 'lodash-es';
/**
 * @description: 根据key查询某一项重复，返回包含重复项的新数组
 * @param {Record<string, any>[]} oldArray
 * @param {string} key
 * @return {Record<string, any>[]}
 */
export const duplicateChecking = (oldArray, key) => {
    const newArray = [];
    for (let i = 0; i <= oldArray.length - 1; i += 1) {
        for (let j = i + 1; j < oldArray.length; j += 1) {
            if (oldArray[i][key] === oldArray[j][key]) {
                let yet = false;
                newArray.forEach((item) => {
                    if (item[key] === oldArray[i][key]) {
                        yet = true;
                    }
                });
                if (yet) {
                    newArray.push(oldArray[j]);
                    break;
                }
                else {
                    newArray.push(oldArray[i]);
                    newArray.push(oldArray[j]);
                    break;
                }
            }
        }
    }
    return newArray;
};
/**
 * @description: 根据key查询某一项重复，返回删除重复项后的新数组
 * @param {Record<string, unknown>[]} oldArray
 * @param {string} key
 * @return {Record<string, unknown>[]}
 */
export const deleteDuplicate = (oldArray, key) => {
    const arrayTemp = cloneDeep(oldArray);
    for (let i = 0; i <= arrayTemp.length - 1; i += 1) {
        for (let j = i + 1; j < arrayTemp.length; j += 1) {
            if (arrayTemp[i][key] === arrayTemp[j][key]) {
                arrayTemp.splice(j, 1);
                j -= 1;
            }
        }
    }
    return arrayTemp;
};
