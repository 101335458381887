import { validateEthLikeAddress } from '@/core/utils/validate';
import { isNumber } from '@/core/utils/number';
/**
 * 批量空投相关业务
 */
class MultiDrop {
    /**
     * 获取地址格式是否正确
     * @param chain 链类型，当前只有eth同构链
     * @param address
     * @returns
     */
    static checkAddress(chain, address) {
        return validateEthLikeAddress(address);
    }
    /**
     * 获取币数量是否正确
     * @param count 输入的数量
     * @param decimal 精度 0时不校验精度
     * @returns
     */
    static checkCount(count, decimal) {
        if (!isNumber(count)) {
            return false;
        }
        if (Number(count) === 0) {
            return false;
        }
        if (decimal) {
            return (count.split('.')[1]?.length || 0) <= decimal;
        }
        return true;
    }
    static getErrorType(chain, address, count, decimal) {
        const addressInvalid = !this.checkAddress(chain, address);
        const countInvalid = !this.checkCount(count, decimal);
        if (addressInvalid && countInvalid) {
            return 3; // 全部错误
        }
        if (addressInvalid) {
            return 1;
        }
        if (countInvalid) {
            return 2;
        }
        return 0;
    }
    // 判断nft空投列表是否出错
    // 返回true表示出错
    static getNftError(standard, address, tokenId, count = '') {
        const validateAddress = validateEthLikeAddress(address);
        if (!validateAddress) {
            return true;
        }
        if (tokenId === '') {
            return true;
        }
        if (!Number.isInteger(Number(tokenId)) || Number(tokenId) < 0) {
            return true;
        }
        if (standard === '1155' && (!Number.isInteger(Number(count)) || Number(count) <= 0)) {
            return true;
        }
        return false;
    }
}
export default MultiDrop;
