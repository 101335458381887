import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
    name: 'StepContainer',
    props: {
        steps: {
            type: Object,
            required: true,
        },
    },
    setup() {
        const { t } = useI18n();
        return { t };
    },
});
