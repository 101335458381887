import axios from 'axios';
import { onBeforeUnmount, ref } from 'vue';
/**
 * 通用发送请求
 * pending状态
 * 销毁前自动取消请求
 * 异常时自动弹窗提示
 * @returns 成功的result
 */
const useRequest = () => {
    const pending = ref(false);
    const cancelSource = ref(axios.CancelToken.source());
    const submit = async (submitter) => {
        pending.value = true;
        try {
            await submitter(cancelSource.value.token);
            pending.value = false;
        }
        catch (e) {
            pending.value = false;
            throw new Error(e);
        }
    };
    onBeforeUnmount(() => {
        cancelSource.value.cancel();
    });
    return {
        pending,
        submit,
    };
};
export default useRequest;
