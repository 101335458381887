import Dropdown from '@/components/Dropdown.vue';
import InputField from '@/components/InputField.vue';
import { defineComponent, computed, ref, watch, } from 'vue';
import OptionsView from './OptionsView.vue';
/**
 * 选项和输入框结合组件，选项不区分label和value
 */
export default defineComponent({
    name: 'SelectInput',
    components: {
        InputField,
        Dropdown,
        OptionsView,
    },
    props: {
        modelValue: {
            type: String,
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        placeholder: {
            type: String,
            required: false,
            default: '',
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const dropdown = ref(null);
        // 输入框绑定值:选中的选项标签或输入的字符串
        const bindValue = ref(props.modelValue || '');
        watch(() => props.modelValue, (val) => {
            if (val !== bindValue.value) {
                bindValue.value = val;
            }
        });
        const selectItem = (item) => {
            emit('update:modelValue', item.label);
            bindValue.value = item.label;
            if (dropdown.value) {
                dropdown.value.hide();
            }
        };
        const inputChangeHandler = (val) => {
            emit('update:modelValue', val);
            bindValue.value = val;
        };
        const inputClickHandler = (e) => {
            e.stopPropagation();
            if (dropdown.value) {
                dropdown.value.hide();
            }
        };
        const optionItems = computed(() => props.options.map((item) => ({
            label: item,
            value: item,
        })));
        return {
            dropdown,
            selectItem,
            inputClickHandler,
            bindValue,
            inputChangeHandler,
            optionItems,
        };
    },
});
