import { getImageSize } from '@/core/utils';
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Toast from './Toast';
/**
 * 点击/拖砖上传文件,不含ui
 * @desc 默认上传图片
 */
export default defineComponent({
    name: 'BaseUploader',
    props: {
        accept: {
            type: String,
            required: false,
            default: 'image/jpeg, image/jpg, image/png',
        },
        acceptError: {
            // 文件类型错误时的通知
            type: String,
            required: false,
        },
        sizeLimit: {
            type: Number,
            required: false,
        },
        rateLimit: {
            // 宽高比限制
            type: Number,
            required: false,
        },
        rateRange: {
            // 宽高比允许的误差，默认30%
            type: Number,
            required: false,
            default: 0.3,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: {
        add: (file) => file,
    },
    setup(props, { emit }) {
        const { t } = useI18n();
        const fileRef = ref(null);
        const boxClickHandler = () => {
            if (props.disabled) {
                return;
            }
            if (!fileRef.value) {
                return;
            }
            fileRef.value.click();
        };
        const emitFile = async (file) => {
            if (props.accept && props.accept.indexOf(file.type) < 0) {
                Toast.error(props.acceptError || t('over_type_limit'));
                return;
            }
            if (props.sizeLimit && file.size > props.sizeLimit) {
                Toast.error(t('over_size_limit'));
                return;
            }
            if (props.rateLimit) {
                const { width, height } = await getImageSize(file);
                const rate = width / height;
                const range = Math.abs(rate - props.rateLimit);
                if (range / props.rateLimit > props.rateRange) {
                    Toast.error(t('over_rate_limit'));
                    return;
                }
            }
            emit('add', file);
        };
        const inputChangeHandler = async (e) => {
            const target = e.target;
            if (!target.files) {
                return;
            }
            if (!target.files) {
                return;
            }
            [...target.files].forEach((file) => {
                emitFile(file);
            });
            if (fileRef.value) {
                fileRef.value.value = '';
            }
        };
        const boxDragHandler = (e) => {
            if (!e.dataTransfer) {
                return;
            }
            const { files } = e.dataTransfer;
            if (!files) {
                return;
            }
            [...files].forEach((file) => {
                emitFile(file);
            });
        };
        return {
            fileRef,
            boxClickHandler,
            inputChangeHandler,
            boxDragHandler,
        };
    },
});
