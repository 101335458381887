import { defineComponent } from 'vue';
export default defineComponent({
    name: 'OptionsView',
    props: {
        options: {
            type: Array,
            required: true,
        },
        isHistory: Boolean,
    },
    emits: ['select'],
    setup(props, { emit }) {
        const selectItem = (target) => {
            emit('select', target);
        };
        return {
            selectItem,
        };
    },
});
